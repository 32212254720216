import React, { Component } from "react"
import { Section, Title, SectionButton } from "../../utils"
import styled from "styled-components"
import { Link } from "gatsby"
import { styles } from "../../utils"

export default class QuickInfo extends Component {
  render() {
    return (
      <Section style={{ width: "100%" }}>
        <Title message="About me" />
        <QuickInfoWrapper>
          <p className="text">
            Hi , I'm eddynardo, a video game designer based in Cyprus. I have
            over 25 years of experience playing games and about half of those
            making them. The first video game I played was Alex Kidd for the
            Sega Master System, which probably explains my love for pixel art 2D
            platformers. Currently I create HTML5 games to pay the rent and work
            on my big PC/Console project in my spare time. Thank you for
            stopping by to play my games, I hope you are having a nice day!!!
            Also, I like cats.
          </p>
          <Link to="/games/" style={{ textDecoration: "none" }}>
            <SectionButton
              style={{
                marginTop: "2rem",
                marginBottom: "2rem",
                marginLeft: "calc(50% - 98.8px)",
              }}
            >
              games
            </SectionButton>
          </Link>
        </QuickInfoWrapper>
      </Section>
    )
  }
}

const QuickInfoWrapper = styled.div`
  width: 90vw;
  margin: 2rem auto;
  .text {
    line-height: 2rem;
    color: ${styles.colors.mainPink};
  }
  @media (min-width: 768px) {
    width: 70%;
  }
  @media (min-width: 992px) {
    width: 60%;
  }
`
