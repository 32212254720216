import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContactHeader } from "../utils"
//import Dozenbear10 from "../assets/Dozenbear10.webm"
import AboutImg from "../images/DozenBear.gif"
import QuickInfo from "../components/HomePageComponents/QuickInfo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <ContactHeader img={AboutImg}></ContactHeader>
    <QuickInfo />
  </Layout>
)

export default AboutPage
